.textTitle {
    font-family: 'Montserrat', sans-serif, 'SemiBold';
    font-weight: 600;
    font-size: 28px;
    padding: 15px;
    padding-top: 0;
    color: #424242;
    margin-top: 30px;
  }
  
  .toggle-button-group {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 50px 0px 30px;
    border: 1px solid #6f969d;
    border-radius: 5px;
    overflow: hidden;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .toggle-button-group button {
    background-color: #fff; /* Устанавливаем белый цвет для фона */
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    outline: none;
    flex: 1;
    transition: background-color 0.3s ease;
  }
  
  .toggle-button-group .active {
    background-color: #6f969d;
    color: #fff;
  }



  .card_form {
    background: #ffffff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.14);
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
    border: none;
    border-radius: 5px;
    overflow: hidden;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    padding: 30px;
    min-height: 400px;
  }
  
  .form_group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  
  .form_label {
    margin-left: 10px;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #6f969d;
  }
  
  .form_control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border 0.15s ease-in-out;
}

.form_control:focus {
  outline: none;
  border: 2px solid #a6b0bb;
}

.containerSelect {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .containerSelect::after {
    content: '▼';
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #555;
    font-size: 14px;
  }

  .form_control-select {
    display: block;
    width: 100%;
    padding: 8px 32px 8px 12px; /* Увеличьте правый отступ */
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    -webkit-appearance: none; /* Удалите стандартную стрелку в Chrome и Safari */
    -moz-appearance: none; /* Удалите стандартную стрелку в Firefox */
    appearance: none; /* Удалите стандартную стрелку в других браузерах */
  }
  
  .form_control-select:focus {
    outline: none;
    border: 2px solid #a6b0bb;
  }
  
 .btn {
    width: 40%;
    font-size: 16px;
    cursor: pointer;
    margin: 30px 30px 10px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  
  .btn:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
  }
  .form_control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
  }
  
  .button_group {
    display: flex;
    justify-content: space-between;
  }
  
  .btn_cancel,  .btn_edit {
    background-color: #6f969d;
    border: none;
  }
  
  .btn_cancel:hover,  .btn_edit:hover {
    background-color: #5a7479;
  }
  
  .btn_save{
    background-color: #4f7078;
    border: 2px solid #47646b;
    border-radius: 5px;

  }
  .btn_save:hover{
    background-color: #7d9ba1;
    border: 2px solid #688489;
  }
  

  .select_disabled {
    background: #e9ecef; /* Измените этот цвет в соответствии с вашими предпочтениями */
  }
  
  .messagePhone {
    position: absolute;
    z-index: 1;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    width: 300px;
    top: 30px; /* Adjust this as needed */
    left: 150px; /* Adjust this as needed */
    font-size: 13px;
  }
  
  .form_group {
    position: relative;
  }


  @media (max-width: 480px){
    .toggle-button-group {
      width: 90%;
      margin-top: 5px;
    }
    .toggle-button-group button {
      padding: 10px 0;
      font-size: 12px;
    }

    .card_form{
      width: 90%;
    }

    .btn{
      width: 100%;
      margin: 0;
    }
  }