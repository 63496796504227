.my-row {
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.my-col {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 1rem;
}

@media (min-width: 768px) {
    .my-col {
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
}

/* WEB */

.title{
    font-family: 'Montserrat', sans-serif, 'SemiBold ';
    font-weight: 600;
    font-size: 25px;
    margin: 20px 0px 0px 00px;
    color: #282e2e;
    text-align: center;
}



@media (min-width: 768px) {
    .flex-item {
        flex-basis: 83.333333%;
        max-width: 83.333333%;
    }
}

  /* фон карточек 1,2,3 */





.stat{
    display: flex;
    flex-wrap: wrap;
}
.cardStat{
    
    border-radius: 5px;
    width: calc(100%/12*11/7);
    height: 100px;
    padding: 10px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: lightgray;
}

.cardStat:hover,
.cardWeek:hover{
    background-color: darkgray;
}


.cardStat > div:nth-child(1),
.cardWeek > div:nth-child(1){
    color: rgb(12, 108, 63);
    text-align: center;
}

.cardStat > div:nth-child(2),
.cardWeek > div:nth-child(2){
    color: black;
    font-size: 9px;
    text-align: center;
}

.cardWeek{
    border-radius: 5px;
    width: calc(100%);
    height: 100px;
    padding: 10px;
    margin: 5px;
    background-color: rgb(181, 181, 181);
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.cardWeek > div{
    text-align: center;
}


.cloud-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background: rgb(255, 255, 255);
    border-radius: 5px;
    margin: calc(2vw + 5px);
    margin-bottom: 0px;
    padding: 10px 20px;
    border: 1px solid rgba(76, 114, 107, 0.6);
}

.cloud-menu-path{
    margin-left: 2vw;
}

.cloud-menu-right {
    margin-left: auto;
    display: flex;
    align-items: center; /* выровнивание по вертикали */
    justify-content: space-between; /* выровнивание по горизонтали */
}

@media (max-width: 767px) {
    .cloud-menu-right{
        display: none;
    }
    
}

.cloud-menu-right h6, .cloud-menu-right button {
    margin: 0; /* удаление отступов */
    padding: 0; /* удаление внутренних отступов */
    line-height: 1.5; /* одинаковая высота строки для текста и кнопки */
}