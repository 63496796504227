@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

.upload_input {
    display: none;
  }
  
  .upload_label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; /* Добавлено */
    padding: 5px 10px;
    cursor: pointer;
    font-size: 13px;
    height: 100%;
}
  .upload_label:hover {
    background-color: #8ca2a7;
  }




  @media (max-width: 576px) {
    .cardFile {
        width: calc(100% / 3 - 20px);
    }
  }


  
  /* Стили для input */

  .containerInput {
    position: relative;
    margin-top: 10px;
  }
  
  .labelForm {
    position: absolute;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 0.8rem;
    color: #2D4B52;
    top: 0;
    left: 0px;
    padding: 0 5px;
    background-color: transparent; 
    transform: translateY(-50%);
  }
  
  .inputForm {
    font-size: 1rem;
    padding: 0.5rem 0.5rem;
    border: none;
    border-radius: 5px;
    text-align: center;
    border-bottom: 2px solid #2B606D;
    width: 100%;
    outline: none;
    transition: border-color 1s;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  .inputForm:focus, .inputForm:hover{
    border-bottom: 3.5px solid #0E3C47;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }


/* Стили для select */

  .containerSelect {
    position: relative;
    margin-top: 10px;
  }
  

  /* Стили для area */
  .textareaForm {
    font-size: 1rem;
    padding: 0.5rem 0.5rem;
    border: none;
    border-radius: 5px;
    border-bottom: 1.5px solid #2B606D;
    width: 100%;
    outline: none;
    transition: border-color 1s;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  
  .textareaForm:focus,  .textareaForm:hover {
    border-bottom: 3.5px solid #0E3C47;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }



  .filesFormFormat>div{
      margin-bottom: 20px;
  }

  /* основа шрифта */
  /* заголовок блока */
  .textH4 {
    font-family: 'Montserrat', sans-serif, 'SemiBold ';
    font-weight: 600;
    font-size: 20px;
    padding: 15px;
    padding-top: 0;
    color: #0E3C47;
  }

  .textH5{
    font-family: 'Montserrat', sans-serif, 'SemiBold ';
    font-weight: 700;
    font-size: 13px;
    padding: 15px;
    padding-top: 0;
    color: #0E3C47;
  }

  .buttonForm {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    color: #ffffff;
    background-color: #2B616D;
    border: none;
    border-radius: 5px;
    padding: 0.8rem 1.7rem;
    text-align: center;
    cursor: pointer;
    transition: color 1s, background-color 0.5s, scale 1s;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.18), 0 1px 2px rgba(0, 0, 0, 0.14);
  }
  
  .buttonForm:hover {
    background-color: #1b4149;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.12), 0 10px 10px rgba(0, 0, 0, 0.24);
    scale: 1.05;
  }

  .buttonForm1{
    background-color: #2B616D;
    font-size: 0.8rem;
    padding: 0.6rem 1.5rem;
  }

  .buttonForm2{
    background-color: #5a838c;
    font-size: 0.6rem;
    padding: 0.5rem 1rem;
  }

  .icon-question-circle {
    font-size: 17px;
    margin-left: 10px;
    transition: font-size 0.5s  ;
  }
  
  .icon-question-circle:hover {
    font-size: 21px; /* Увеличение размера шрифта иконки при наведении */
    color: #000000;
  }

  .navBarForm {
    background-color: #f9f9f9;
    border-radius: 50px;
    padding: 5px;
    padding-left: 0;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.18), 0 1px 2px rgba(0, 0, 0, 0.14);
    margin-top: 30px;
    position: relative;
  }
  
  .navBarContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .navBarLogo {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-left: 10px;
  }
  
  .navBarTitle {
    margin: 0;
    margin-left: 20px;
    flex-grow: 1;
    text-align: left;

    font-family: 'Montserrat', sans-serif, 'SemiBold ';
    font-weight: 800;
    font-size: 27px;
    padding-top: 0;
    color: #0E3C47;
  }
  
  .navBarButtons {
    display: flex;
    margin-right: 10px;
  }
  
  .navBarButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-right: 20px;

    font-family: 'Montserrat', sans-serif, 'SemiBold ';
    font-weight: 400;
    font-size: 16px;
    padding-right: 0px;
    color: #0E3C47;
  }
  
  .loginForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 100%;
    right: 10px;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.18), 0 1px 2px rgba(0, 0, 0, 0.14);
  }
  
  .loginForm input {
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
  }


  .selectContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;

}

.selectContainer .containerSelect {
  flex-basis: calc(33.33% - 10px);
  max-width: 200px;
}

.filesFormFormat_footer{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.pageUpload{
  margin-top: 80px;
}

.pageUpload_info{
  margin-top: 40px;
  
}

.pageUpload_info h6{
  font-size: 12px;
}

.pageAfterUpload{
  margin-top: 80px;
}