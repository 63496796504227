.blockFile {
    width: calc((96vw - 91px)/7);
    height: calc((96vw - 70px)/7);
    margin: 5px;
    background-color: #ffffff;
    border-radius: 5px;
    overflow: hidden; /* Это скроет любой контент, выходящий за пределы блока. */
    border: 1px solid rgb(198, 198, 198);
    
     /* отключение выделения текста */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Нестандартный Chrome и Opera, а также стандарт для будущих браузеров */
}

@media (max-width: 1500px) {
  .blockFile {
    width: calc((96vw - 70px)/7);
    height: calc((96vw - 70px)/7);
  }
}

@media (max-width: 1023px) {
    .blockFile {
      width: calc((96vw - 50px)/5);
      height: calc((96vw - 50px)/5);
    }
  }
  
  @media (max-width: 767px) {
    .blockFile {
      width: calc((96vw - 30px)/3);
      height: calc((96vw - 30px)/3);
    }
  }

  .fileName{
    height: 25px;
    font-size: 10px;
    text-align: center;
    padding: 5px;
    
    display: flex;
    justify-content: space-around;
  }

  .fileName :first-child{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .imageContainer {
    width: 100%;
    height: calc(100% - 50px);
    padding: 0 25px;
    text-align: center;
  }

  .imageFull{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.buttonGroup1{
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
  padding: 3px;
} 

      .buttonGroup1 button{
        height: 100%;
        border: 1px solid rgb(198, 198, 198);
        border-radius: 3px;
        background: #ffffff;
      }

      .buttonSize{
        width: 60%;
        font-size: 11px;
      }
      .buttonDelete{
        width: 20%;
        font-size: 11px;
      }
    
      

.iconMenu{
  color: rgb(2, 128, 128);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(100vw / 25);
}

@media  (max-width: 767px) {
  .iconMenu{
    font-size: 11vw;
  }

  .buttonSize{
    flex: 3;
    max-width: 72%;
    color: black;
    font-size: 10px;
    white-space: nowrap;
    overflow: hidden;
  }

  .buttonDelete{
    flex:1;
    font-size: 10px;
  }



}

