.filesList {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 2vw;
  }

  .menu{
    margin: 0 calc(2vw + 5px);
    padding: 20px 5px 5px;
    font-family: 'Montserrat', sans-serif, 'SemiBold ';
    font-weight: 500;
    font-size: 14px;;
    display: flex;
    justify-content: flex-end;
  }