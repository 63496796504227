.cardFile {
    width: calc(100% / 8 - 10px);
    height: calc(12.5vw - 40px);
    overflow: hidden; 
    position: relative;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    display: flex;
    flex-direction: column;
    background-color: transparent;
  } 

  @media (max-width: 769px) {
    .cardFile{
      width: calc(100% / 3 - 4px);
      height: calc(33vw - 30px);
    }
  }

.cardImageTop {
    width: 100%;
    /* Другие стили, которые вы хотите применить к изображению */
}
  
  .cardFileInner {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
  }
  
  .cardFile img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
    transition: opacity 0.3s ease;
  }
  
  .closeButton {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    border: none;
    color: rgb(255, 113, 113);
  }

  .closeButton:hover{
    transform: scale(1.1);
  }