
.card_footer {
    background: #a4aeaf;
    border: 1px solid #7ca1a6;
    border-radius: 5px;
    font-size: 15px;
    margin: 15px 0px 50px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.18), 0 1px 2px rgba(0, 0, 0, 0.14);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  

  .card_footer_1 {
    flex-grow: 4;
    flex-basis: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 9%;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    min-width: 0; /* добавить эту строку */
    padding: 0 10px;
  }

  .card_footer_2 {
    flex-grow: 2;
    flex-basis: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    min-width: 0; /* добавить эту строку */
    padding: 0 10px;
  }
  .card_footer_3 {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    min-width: 0; /* добавить эту строку */
    padding: 0 10px;
  }

 