 .auth{
    width: 60%;
    margin: auto;
    padding: 5vw 5vw;
    border-radius: 10px;
    background: #ffffff;
    font-family: 'Montserrat', sans-serif, 'SemiBold ';
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 30px;
 } 

 .row{
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10%;
    
 }



 .title{
    text-align: center;
    width: 100%;
    font-weight: 800;
    font-size: 23px;
 }




 .inputForm{
   border: none;
   width: 100%;
   height: 50px;
   border: 1px solid rgb(58, 58, 58);
   border-radius: 5px;
   padding: 0 30px;
   text-align: center;
}


 .btnSend{ 
    width: 40%;
    min-width: max-content;
    border: none;
    border-radius: 5px;
    
    padding: 10px 20px;
    height: 50px;
    background: #116466;
    color: #ffffff;
    white-space: nowrap;
 }

 .btnSend:hover{
    background: #093e3f;
 }


 @media (max-width: 769px) {
   .auth{
      width: 90%;
   }
   .btnSend{
      width: 80%;
      font-size: 14px;
   }
   .row{
      gap: 5px;
   }
 }


