.container{
    border: 1px solid silver;
    padding: 15px 5px;
    border-radius: 5px;
} 

.rowMenu{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.typeButton{
    width: 10vw;
    min-width: max-content;
    padding: 2px 5px;
    background: #eaeaea;
    border-radius: 3px;
    margin: 5px 5px;
    color: #2C3531;
    border: 1px solid #2C3531;
    text-align: center;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: scroll;
    width: 79vw; /* Задана фиксированная ширина для .row */

  }

            .row::-webkit-scrollbar {
                height: 10px; /* Ширина полосы прокрутки */

            }
            
            .row::-webkit-scrollbar-track {
                background: #e6e6e6; /* Цвет фона полосы прокрутки */
                border-radius: 5px;
            }
            
            .row::-webkit-scrollbar-thumb {
                background: #bababa; /* Цвет полосы прокрутки */
                border-radius: 5px;
            }
            
            .row::-webkit-scrollbar-thumb:hover {
                background: #555; /* Цвет полосы прокрутки при наведении */
            }
  
  .imageContainer {
    min-width: calc(80vw/10);
    max-width: calc(80vw/10);
    height: calc(80vw/10);
    text-align: center;
    border: 1px solid rgb(198, 198, 198);
    margin: 5px;
    background-color: #ffffff;
    border-radius: 5px;
  }

@media (max-width: 769px){
    .imageContainer {
        min-width: calc(80vw/4.5);
        max-width: calc(80vw/4.5);
        height: calc(80vw/4.5);
    }
    .row{
        width: 99%;
    }
}

.imageFull{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

