@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');


.menu-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0px 15px;
  }
  
.menu-left, .menu-right {
  display: flex;
  align-items: center;
}

.menu-button {
  background-color: #2f616b;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.1s ease;
  height: 30px;
  width: 100px;
  min-width: 50px;
}

.menu-button:hover {
  background-color: #434343;
  border: 1px solid #000000e2;
}

.menu-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: 'Montserrat', sans-serif, 'SemiBold';
  font-weight: 500;
  font-size: 11px;
  background: #ffffff;
  color: #000000;
  border: 1px solid #2f616b;
  padding: 0 10px;
  margin-left: 10px;
  margin-right: 10px;
  height: 30px;
  width: 35%;
}

.menu-select:focus {
  outline: none;
  box-shadow: none;
}

.menu-input {
  display: block;
  padding: 0.375rem 0.75rem;
  font-family: 'Montserrat', sans-serif, 'SemiBold';
  font-weight: 500;
  font-size: 11px;
  line-height: 1.5;
  background: #ffffff;
  color: #000000;
  border: 1px solid #2f616b;
  background-clip: padding-box;
  border-radius: 4px;
  transition: border 0.15s ease-in-out;
  height: 30px;
  width: 25%;
}

.menu-input:focus {
  outline: none;
  border: 2px solid #3f5154;
}


.status-filter-popup {
  width: 160px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  background-color: #f5f5f5;
  border: 1px solid #949494;
  border-radius: 5px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.18), 0 1px 2px rgba(0, 0, 0, 0.14);
}

.status-filter-popup label {
  font-size: 11px;
  color: #004538;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.status-filter-popup label:hover {
  background-color: #e9ecef;
}

.status-filter-popup input[type="checkbox"] {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  appearance: none;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  position: relative;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.status-filter-popup input[type="checkbox"]:checked {
  background-color: #00755f;
  border-color: #00755f;
  box-shadow: inset 0 0 0 2px #fff;
}

.status-filter-popup input[type="checkbox"]:focus {
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

.status-filter-container {
  position: relative;
  display: inline-block;
}


.status-filter-popup {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;
}

.tableFull {
  overflow-x: scroll;
  white-space: nowrap;
}


@media (max-width: 480px) {
  .menu-button {
     
  }
  .menu-select{
    width: 25%;
    margin: 0 1px;
  }
  .menu-container{
    margin: 15px 2px 15px;
  }
 
}