.modal{
    position: relative;
    width: 100vw;
    background: rgb(255, 255, 255);
    transform: translateX(100%); /* Начальное положение элемента за пределами экрана слева */
    transition: transform 0.15s ease-out; 
}

        .modalActive{
            transform: translateX(0);
        }



.menu{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    font-size: 12px;
    margin: 20px 10px;
    gap: 5px;
}

        .menu div{
            flex: 1;
            text-align: center;
        }

        .buttonBack{
            min-width: 60px;
            margin-right: 30px;
            border: 0.5px solid rgb(144, 144, 144);
            border-radius: 5px;
            background: rgb(255, 255, 255);
        }

        .circle{
            border-radius: 20px;
            border: 1px solid rgb(157, 179, 127);
            padding: 0 5px;
        }

.cardContact{
    margin: 5px 10px;
    border-top: 1px solid #116466;
    padding: 10px;
}

        .contactField{
            display: flex;
            flex-direction: row;
            font-size: 14px;
            align-items: center;
        }
        .contactField label{
            flex: 1;
        }
        .contactField input{
            height: 25px;
            flex: 3;
        }

        .buttons{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 30px;
        }

