@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap');



.order_card_t {
  background: rgb(255, 255, 255);
  width: 100%;
  display: inline-block;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  margin: 3px 0px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.18), 0 1px 2px rgba(0, 0, 0, 0.14);
}



.order_card_t:hover {
  background: #f0f0f0; 
}

.order_card_t_expanded {
  border: 3px solid #6f969d; 
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.18), 0 1px 2px rgba(0, 0, 0, 0.14);
  margin: 15px 0px;
}

.order_card_main_t {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 3px 3px 10px;
  min-width: 0;
}

.order_card_main_t_expanded{
  background: #6f969d; 
}



.flex_col {
  
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  min-width: 100px; /* добавить эту строку */
  padding: 0 10px;
}

.flex_col_sm {
  display: flex;
  justify-content: center;
  padding: 0 10px;
  align-items: center;
  font-size: 12px;
  min-width: 120px;
}

.first_col {
  color: #298390;
  min-width: 40px;
  padding: 0 5px;
}

.col_origin{
  font-size: 12px;
  flex: 1;
  padding: 0 10px;
  justify-content: center;
}

.col_data{
  font-size: 12px;
  flex: 2;
  padding: 0 10px;
  justify-content: center;
}

.col_number{
  font-size: 12px;
  flex: 1;
  padding: 0 10px;
  justify-content: center;
}

.col_fio{
  font-size: 12px;
  flex: 4;
  padding: 0 10px;
  justify-content: center;
}

.col_phone{
  font-size: 12px;
  flex: 4;
  padding: 0 10px;
  text-align: center;
  justify-content: center;
}

.col_city{
  font-size: 12px;
  flex: 3;
  padding: 0 10px;
  text-align: center;
  justify-content: center;
}

.col_photo{
  font-size: 12px;
  flex: 6;
  padding: 0 10px;
  text-align: center;
  justify-content: center;
}

.col_warn{
  font-size: 12px;
  flex: 1;
  min-width: 100px;
  padding: 0 10px;
  justify-content: flex-start;
}

.col_price{
  font-size: 12px;
  flex: 1;
  min-width: 80px;
  padding: 0 10px;
  justify-content: center;
}

.overflow{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select_col{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 13px;
  border: 1px solid #298390;
  padding: 0 10px;
  margin-left: 10px;
  height: 24px;
}

.select_col:hover{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media (max-width: 768px) {
  .order_card_t{
    width: 600px;
  }
  .flex_col {
    width: 50px;
  }
  .flex_col_sm{
    width: 15px;
    margin-left: 5px;
  }
  .col_origin{
    max-width: 20px;
    padding: 2px;
    margin: 0;
  }
  .col_data{
    max-width: 80px;
    padding: 2px;
    margin: 0 5px;
  }
  .col_number{
    max-width: 50px;
    text-align: center;
    padding: 2px;
    margin: 0;
  }
  .col_photo{
    max-width: 150px;
    text-align: center;
    padding: 2px;
    margin: 0;
  }
  .col_warn{
    max-width: 99px;
    text-align: center;
    padding: 2px;
    margin: 0;
  }
  .col_price{
    max-width: 90px;
    text-align: center;
    padding: 2px;
    margin: 0;
  }
  .select_col{
    width: 50px;
    padding: 2px;
  }
  .NoMobile{
    display: none;
  }

}


@media print {
  @page {
    size: 6cm 8cm;
    margin: 0;
  }
  body {
    margin: 0;
    font-size: 10px;
  }
  .print-content {
    width: 6cm;
    height: 8cm;
    /* Остальные стили */
  }
}
