/* Файл loader.css */


.placeholder {
    width: 90%;
    margin: 0 auto;
    height: 30px;
    background-color: #e0e0e0;
    border-radius: 5px;
    animation: pulse 1.5s ease-in-out infinite;
  }
  
  @keyframes pulse {
    0% {
      background-color: #e0e0e0;
    }
    50% {
      background-color: #9f9f9f;
    }
    100% {
      background-color: #e0e0e0;
    }
  }
  
  .content {
    width: 100%;
    height: 40px;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
  }