.order_details_card {
  background: white;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;

}

.card_container_admin {
  display: flex;
  gap: 10px;
  width: 100%;
}



.card_admin {
  background: #ffffff;
  border: 1px solid rgb(237, 237, 237);
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.card_container_admin>div:nth-child(1){
  flex: 1;
}
.card_container_admin>div:nth-child(2){
  flex: 1;
}
.card_container_admin>div:nth-child(3){
  flex: 1;
}

.card_admin>div:first-child{
  margin-bottom: 25px;
}

.card_actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;

}

.save_button,
.copy_button,
.delete_button {
  padding: 4px 8px;
  width: 40%;
  font-size: 13px;
  
  border: none;
  color: rgb(0, 0, 0);
  border-radius: 5px;
  cursor: pointer;
}
.save_button{
  background-color: #bbbbbb;
}
.save_button:hover{
  background-color: #757575;
}

.copy_button{
  background-color: #bbbbbb;
}
.copy_button:hover{
  background-color: #757575;
}

.delete_button{
  background-color: #ffe9e9;
}

.delete_button:hover {
  background-color: #d8acac;
}

.price_input{
  padding: 4px 8px;
  width: 40%;
  font-size: 13px;
  text-align: center;
  border: 1.5px solid rgb(153, 153, 153);
  border-radius: 5px;
}

.contact_field{
  display: flex;
  justify-content: space-between;
}

.contact_field >label{
  flex: 1;
  margin: 3px 0;
  font-family: 'Montserrat', sans-serif, 'SemiBold';
  font-weight: 500;
}

.contact_field >input{
  flex: 2;
  margin: 3px 0;
  border-radius: 3px;
  border: 1px solid #137f6b;
  padding: 2px 5px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.18), 0 1px 2px rgba(0, 0, 0, 0.14);
}

.contact_field > input[type="checkbox"] {
  height: 20px;
}

.contact_field >select {
  flex: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #ffffff;
  border: 1.7px solid #137f6b;
  border-radius: 3px;
  padding: 2px 5px;
  margin: 20px 0 3px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.18), 0 1px 2px rgba(0, 0, 0, 0.14);
}

.contact_field >select:focus {
  outline: none;
  box-shadow: none;
}

.origin{
  display: flex;
  flex-direction: row;
  font-family: 'Montserrat', sans-serif, 'SemiBold';
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 13px;
}

.origin label{
  width: max-content;
  margin-right: 10px;
}

.origin >select {
  flex: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #ffffff;
  border: 1.7px solid #137f6b;
  border-radius: 3px;
  padding: 0px 15px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.18), 0 1px 2px rgba(0, 0, 0, 0.14);
}

.inputData {
  padding: 5px 20px;
  font-family: 'Montserrat', sans-serif, 'SemiBold';
  font-weight: 500;
  font-size: 11px;
  background: #ffffff;
  color: #000000;
  border: 1px solid #2f616b;
  border-radius: 4px;
  width: max-content;
  height: 25px;
}

.inputData:focus {
  outline: none;
  border: 2px solid #3f5154;
}


.format_group{
  display: flex;
  justify-content: space-between;
  padding: 5px 0px;
  align-items: center; 
  height: 25px;
  font-size: 13px;
}


.format_group>input{
  background: #f2f2f2;
  flex: 3;
  width: 0;
  height: 30px;
  border-radius: 3px 0 0  3px;
  border: 1px solid #137f6b;
  text-align: center;
  padding: 0px 5px;
}

.format_group>select{
  background: #f2f2f2;
  -webkit-appearance: none; /* Добавлено для Safari и Chrome */
  -moz-appearance: none; /* Добавлено для Firefox */
  appearance: none;
  flex: 3;
  width: 0;
  height: 30px;
  border-radius: 0px;
  border: 1px solid #137f6b;
  border-width: 1px 1px 1px 0px;
  padding: 0px 5px;
  text-align: center;
  text-align-last: center;
}

.format_group>select:last-of-type{
  border-radius: 0 3px 3px  0;
}
.format_group > button {
  flex: 1;
  background:rgba(255, 239, 239, 0.705);
  border: none;
  border-radius: 3px;
  margin-left: 10px;
  height: 20px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info_other{
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.info_other>label{
  font-family: 'Montserrat', sans-serif, 'SemiBold';
  font-weight: 500;
  font-size: 13px;
}

.info_other>textarea{
  border-radius: 3px;
  border: 1px solid #137f6b;
}

.input-changed {
  border-color: red; 
}

.search_bar {
  flex: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  
}

.search_bar > input{
  outline: none;
  border-bottom: 1px solid #137f6b;
  margin: 3px 0;
  border-radius: 3px;
  border: 1px solid #137f6b;
  padding: 2px 5px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.18), 0 1px 2px rgba(0, 0, 0, 0.14);
}

.search_bar > input:hover{
  border-radius: 3px 3px 0 0;
  border-width: 1.5px 1.5px 0 1.5px;
}
