.containerMain{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  margin-top: 30px;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.container select,
.container input,
.container button {
    width: 10%;
    height: 25px;
    margin: 2px 15px;
    box-sizing: border-box;
}