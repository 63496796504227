.row{
    width: 100%;
    display: flex;
    justify-content: center;
}

.col{
    width: 91.5%;
}

@media (max-width: 769px){
    .col{
        width: 100%;
    }
}