.contactForm{
    background-color: #fff;
    border-radius: 5px;
    padding: 20px 10px;
    box-shadow: 0px 0px 6px 1px rgba(61, 96, 94, 0.38);
    margin-top: 20px;
}

.textH4{
    font-family: 'Montserrat', sans-serif, 'SemiBold ';
    font-weight: 600;
    font-size: 20px;
    padding: 5px;
    padding-bottom: 25px;
    padding-top: 0;
    color: #2C3531;
}

@media (max-width: 769px) {
    .contactForm{
        padding: 15px;
        margin: 20px 0;
    }
    .textH4{
        font-size: 16px;
        margin: 10px 0 15px;
    }
}


.rowInfo{
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 20px;
}

@media (max-width: 769px) {
    .rowInfo{
        flex-direction: column;
        padding: 0 20px;
    }
}

.containerInput{
    flex: 1;
    position: relative;
   
}

        .containerInput label{
            position: absolute;
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            font-size: 0.8rem;
            color: #2D4B52;
            top: 0;
            left: 0px;
            padding: 0 5px;
            background-color: transparent; 
            transform: translateY(-50%);
        }

        .containerInput input{
            outline: none;
            font-size: 0.9rem;
            padding: 0.5rem 0.5rem;
            border: none;
            border-radius: 3px;
            text-align: center;
            border-bottom: 2px solid #2C3531;
            box-shadow: 0 3px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            width: 100%;
            height: 35px;
        }

.containerSelect {
    flex: 1;
    position: relative;
    margin-right: 8vw;
}

@media (max-width: 769px) {
    .containerSelect{
        width: 90%;
        margin-right: 0;
        margin-left: auto;
        
    }
}

        .labelSelect {
            position: absolute;
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            font-size: 0.8rem;
            color: #2C3531;
            top: 0;
            left: 0;
            padding: 0 5px;
            background-color: transparent;
            transform: translateY(-50%);
        }

        .selectForm {
            font-size: 0.9rem;
            padding: 0.5rem 0.5rem;
            border: none;
            text-align: center;
            border-bottom: 1.5px solid #2C3531;
            min-width: 200px;
            width: 100%;
            height: 35px;
            outline: none;
            transition: border-color 1s; 
            -webkit-appearance: none;
            appearance: none;
            background: white;
            color: black;
            box-shadow: 0 3px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        }
        .selectArrow {
            position: absolute;
            right: 10px;
            top: 50%;
            pointer-events: none; /* Убирает взаимодействие с указателем мыши */
            transform: translateY(-50%);
          }

.textAreaForm {
    font-size: 1rem;
    padding: 0.5rem 0.5rem;
    border: none;
    border-radius: 3px;
    border-bottom: 1.5px solid #2C3531;
    width: 50%;
    outline: none;
    transition: border-color 1s;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  @media (max-width: 769px) {
    .textAreaForm {
        width: 100%;
    }
}