.menuEditor{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 50%;
  border-bottom: 1px solid rgb(92, 96, 95);
  padding-bottom: 20px;
  margin: 20px 0;
}

@media (max-width: 769px){
  .menuEditor{
    width: 100%;
  }
  
}

.fileInput {
  flex: 8 ;
  height: 30px;
  border: 1px dashed rgb(96, 117, 111);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 40px;
}

.inputMenu{
  flex: 4;
  height: 30px;
  border: 1px solid rgb(96, 117, 111);
  margin: 5px;
  border-radius: 5px;
}

.inputMenuIn{
  width: 100%;
  border: none;
  border-radius: 5px;
  text-align: center;
}

.inputMenuIn:focus {
  outline: none;
}

.selectMenuOut{
  flex: 4;
  height: 30px;
  border: 1px solid rgb(96, 117, 111);
  margin: 5px;
  border-radius: 5px;
  display: block;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;

}
.selectMenuOut:after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  padding: 3px 15px 3px 3px;
  background-color: #ffffff;
  cursor: pointer;
  pointer-events: none;
  transition: 0s all ease;
  z-index: 1;
}

.selectMenuIn{
  width: 100%;
  height: 100%;
  padding-left: 15px;
  background: transparent;
  cursor: pointer;
  appearance: none;
  border: none;
  position: relative;
  z-index: 2;
}

.buttonMenu{
  flex: 1;
  height: 30px;
  margin: 5px;
  border: 1px solid rgb(96, 117, 111);
  background: #ffffff;
  border-radius: 5px;
}

.settingsContainer {
  position: relative;
  z-index: 9999;
  /* другие стили */
}

.settingsEditor{
  position: absolute;
  left:-250px;
  width: 300px;
  border: 1px solid rgb(96, 117, 111);
  background: #ffffff;
  border-radius: 5px;
  padding: 15px;
}

.settingsEditorRow{
  display: flex;
  flex-direction: row;
}

.settingsEditorRow label{
  flex:1;
}

.settingsEditorRow input{
  flex:1;
  min-width: 0;
  max-width: 100%;
}

.tableEditor {
  border: 1px dashed black;
  margin: auto;
  margin-top: 20px;
}

.tableEditor td {
  border: none;
  height: 40px;
  width: 40px;
}

.tableEditor input {
  width: 35px;
}


.editMain {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:flex-start;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(92, 96, 95);
}
 
.containerOne{
  width: 25%;
  padding: 10px;
}

.imageBlock {
  position: relative;
  width: 100%;
  height: calc(100vw/4 + 40px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid rgb(184, 184, 184);
  box-shadow: 5px 6px 10px -5px rgba(128, 128, 128, 0.6);
  border-radius: 5px;
}

@media(max-width: 769px){
  .imageBlock {
    margin: 10px 40px;
    width: calc(100vw - 20px);
    height: calc(100vw - 90px);
  }
}

.imageInfo{
  
  font-size: 12px;
  height: 30px;
  background: #dddddd;
  border-radius: 5px;
  border: 0.5px solid #b1b1b1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.imageInfo label{
  padding: 5px;
  text-align: center;
  flex: 3;
  background: #dddddd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.imageInfo button{
  border: 0.5px solid #b1b1b1;
  border-radius: 2px;
  background: #dddddd;
  flex: 1;
}

.imageCropper{
  position: relative;
  height: calc(100vw/4 - 42px);
  margin: 10px;
}

.containerClassName{  /*область cropper*/
  

}

.mediaClassName { /*область фотографии*/
  
}

.cropAreaClassName { /*область обрезки*/
  border: 1px dotted rgb(0, 0, 0) !important;
  color: rgba(255, 255, 255, 0.7)!important;

}



.imageButtonGroup {
  display: flex;
  justify-content: space-between;
  height: 30px;
  background: #dddddd;
}

.imageButtonGroup button {
  flex-grow: 1;
  text-align: center; /* Чтобы текст кнопки был по центру */
  border: 0.5px solid #b1b1b1;
  border-radius: 2px;
  background: #dddddd;
  font-size: 12px;
}

.imageButtonGroup i{
  color: black;
}



.btnSaveEditor{
  margin: 20px;
  width: 150px;
  height: 40px;
  border: 2px solid rgb(96, 117, 111);
  background: #ffffff;
  border-radius: 5px;
}

.btnDeleteEditor{
  margin: 20px;
  margin-left: 0;
  width: 100px;
  height: 30px;
  border: 1px solid rgb(150, 154, 153);
  background: #ffffff;
  border-radius: 5px;
}

.zoomDiv{
  padding: 0 10px;
  width: 70%;
}

.zoomRange {
  margin: auto;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
  background: #3f51b5;
  width: 100%;
}
