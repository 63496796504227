/* Обновленные стили */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
  .offcanvas {
    position: fixed;
    top: 0;
    left: -300px;
    width: 300px;
    height: 100%;
    background-color: #f3f3f3;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    z-index: 1000;
  }
  
  .offcanvas.show {
    left: 0;
  }
  
  .offcanvas-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .offcanvas-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    background-color: #576c75;
    border-bottom: 1px solid #ccc;
  }
  
  .offcanvas-title {
    margin: 0;
    font-family: 'Montserrat', sans-serif, 'SemiBold ';
    font-weight: 800;
    font-size: 20px;
    color: rgb(255, 255, 255);
  }
  
  .close-btn {
    display: none;
  }
  
  .offcanvas-body {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .menu-btn {
    background-color: rgb(255, 255, 255);
    color: #343a40;
    font-size: 13px;
    padding: 8px 16px;
    border: 1px solid #576c75;
    cursor: pointer;
    text-align: center;
    width: 100%;
    border-radius: 4px;
    transition: all 0.3s ease;
  }
  
  .menu-btn:hover {
    background-color: rgb(226, 226, 226);
  }
  
  .menu-btn.active {
    font-weight: bold;
    background-color: #bbbbbb;
  }
  
  .user-info {
    font-family: 'Montserrat', sans-serif, 'SemiBold ';
    font-weight: 400;
    font-size: 12px;
    padding: 13px;
    border-top: 1px solid #ccc;
  }