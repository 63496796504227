/* Footer.css */
.footer {
    background-color: #363636;
    padding: 8px;
    position: relative;
    bottom: 0;
    width: 100%;
    margin-top: auto;
  }
  
  .footerContent {
    display: flex;
    justify-content: space-between;
  }
  
  .footerLinks {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    color: silver;
  }

  .footerLinks>div{
    margin: 10px;
    
  }

  .footerLinks label, a, i{
    color: rgb(163, 163, 163);
  }

  .footerLinks a{
    text-decoration: none;
  }

  .footerLinks div:hover a,
  .footerLinks div:hover i {
      color: rgb(255, 255, 255);
  }
  
  .footerCopyright {
    text-align: center;
    margin-top: 20px;
  }

  @media (max-width: 480px){
    .footer{
      padding: 5px;
      font-size: 12px;
    }
  }