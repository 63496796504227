.footer{
    font-family: 'Montserrat', sans-serif, 'SemiBold ';
    font-weight: 400;
    padding: 5px 20px;
    margin: 20px 0;
    height: 35px;
    border-radius: 5px;
    background: grey;
    color: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 13px;
}

.menu{
    font-family: 'Montserrat', sans-serif, 'SemiBold ';
    font-weight: 400;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
}

.menu input{
    height: 25px;
    border-radius: 5px;
    border: none;
    color: #116466;
    border: 1.5px solid #116466;
    padding: 0px 5px;
}

@media (max-width: 769px) {
    .menu input{
        width: min-content;
    }
}

.container{
    font-family: 'Montserrat', sans-serif, 'SemiBold ';
    font-weight: 400;
}

.listVoid{
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.container button{
    width: max-content;
    border: none;
    border-radius: 5px;
    color: white;
    border: 1.5px solid #116466;
    background: #116466;
}