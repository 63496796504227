.wrapper{
    display: flex;
    flex-direction: column;
    background: #eaeaea;
    min-height: 100vh;
}

.titlePage{
    font-family: 'Montserrat', sans-serif, 'SemiBold ';
    font-weight: 900;
    font-size: 30px;
    margin-top: 40px;
    color: #17252A;
    text-align: center;
} 

@media (max-width: 769px) {
    .titlePage {
        font-size: 20px;
        margin-top: 15px;
    }
}

.flexContainer {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
}
@media (max-width: 769px) {
    .flexContainer {
        width: 95%;
    }
}

.filesForm {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 30px;
    box-shadow: 0px 0px 6px 1px rgba(61, 96, 94, 0.38);
    margin-top: 20px;
}

.textH4{
    font-family: 'Montserrat', sans-serif, 'SemiBold ';
    font-weight: 600;
    font-size: 20px;
    padding: 5px;
    padding-bottom: 25px;
    padding-top: 0;
    color: #2C3531;
}

@media (max-width: 769px) {
    .filesForm {
        padding: 10px;
    }
    .textH4{
        font-size: 16px;
        margin: 10px 0 15px;
    }
}

.formatNavigate{
    width: 100%;
    display: flex;
    gap: 2px;
}

.formatNavBtn{
    width: 20%;
    border: none;
    background: #298687;
    border: 1px solid #298687;
    border-radius: 5px 5px 0 0 ;
    margin-right: 5px;
    color: white;
}

@media (max-width: 768px){
    .formatNavBtn{
        font-size: 12px;
        white-space: nowrap; /* запрет переноса текста на новую строку */
        overflow: hidden; /* скрытие содержимого, которое выходит за пределы блока */
        text-overflow: ellipsis;
    }
}

.formatNavBtnAdd{
    background: #e1eceb; 
    border: none; 
    width: 5%;
    border-radius: 5px 5px 0 0 ;
    margin-right: 5px;
}

@media (max-width: 769px) {
    .formatNavBtn{
        width: 40%;
        margin: 0;
    }
    .formatNavBtnAdd{
        width: 18%
    }
}

.navLabel{
    display: flex;
    justify-content: space-around;
    white-space: nowrap;
}

.navLabelDelete{
    min-width: max-content;
}

@media (max-width: 769px) {
    .navLabel{
        display: flex;
        padding-left: 0;
    }
    .navLabel div{
        white-space: nowrap; /* запрет переноса текста на новую строку */
        overflow: hidden; /* скрытие содержимого, которое выходит за пределы блока */
        text-overflow: ellipsis;
    }
}

.formatsInfo{
    margin-top: 40px;
    display: flex;
    gap: 10px;
    font-family: 'Montserrat', sans-serif, 'SemiBold ';
    font-weight: 200;
    font-size: 13px;
    
} 

.formatsInfoItem{
    border: 1px solid #116466;
    border-radius: 3px ;
    padding: 5px 20px;
    text-align: center;
    color: #2C3531;
}



.formatsInfoAll{
    margin: 10px 0 0 20px;
    font-family: 'Montserrat', sans-serif, 'SemiBold ';
    font-weight: 800;
    font-size: 15px;
}

@media (max-width: 769px) {
    .formatsInfo{
        flex-wrap: wrap;
    }
    .formatsInfoItem{
        width: 48%;
        white-space: nowrap;
    }
    .formatsInfoAll{
        margin: 15px 0 15px;
        text-align: center;
        font-size: 18px;
    }
}

